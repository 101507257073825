import clx from 'classnames';
import Footer from '@/organisms/Footer';
import NavBar from '@/organisms/NavBar';
import { BACKGROUND_POSITION_TO_CLASSNAME } from 'types/utils';
import { LayoutProps } from '../types';

const WideLayout = ({ children, acf: { header, footer, background }, template }: LayoutProps) => {
  const withHeader = header !== 'hidden';

  const withFooter = footer !== 'hidden';

  return (
    <div className="flex flex-col min-h-screen bg-sand-50 relative" data-attr-template-name={template} id="top">
      {withHeader && <NavBar />}
      <main
        className={clx(
          'relative z-[2] grow',
          withFooter && 'pb-footer',
          !withHeader && !withFooter && 'flex items-center justify-center',
        )}
      >
        {children}
      </main>
      {withFooter && <Footer />}
      {background.image && (
        <div
          className={clx(
            'absolute h-screen w-screen bg-no-repeat bg-cover',
            BACKGROUND_POSITION_TO_CLASSNAME[background.position],
          )}
          style={{
            backgroundImage: background.image.sizes['1536x1536']
              ? `url(${background.image.sizes['1536x1536']})`
              : undefined,
          }}
        />
      )}
    </div>
  );
};

export default WideLayout;
